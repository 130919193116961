
import { SP_addProductToShoppingCart, SP_getCurrentShoppingCart, SP_removeProductFromShoppingCart, SP_updateProductQuantity } from './shopping_cart.js';
$(document).on('change', '#products-filter-container .filter-input', function(){
    products_pagination.page = 1;
    getProducts();
});
$(document).on('click', '.category-checkbox', toggleCategoryCheckbox);
$(document).on('click', '#products-list .product-list-item .quantity-down', downQuantity);
$(document).on('click', '#products-list .product-list-item .quantity-up', upQuantity);
$(document).on('click', '#products-list .product-list-item-size', changeProductSize);
$(document).on('click', '#products-list .product-list-item .add-to-shopping-cart', addProductToShoppingCart);
$(document).on('click', '#shopping-cart-badge-container .shopping-cart-list-quantity .fa-chevron-down', decreaseProductQuantity);
$(document).on('click', '#shopping-cart-badge-container .shopping-cart-list-quantity .fa-chevron-up', increaseProductQuantity);
$(document).on('change', '#shopping-cart-badge-container .shopping-cart-list-quantity .product-quantity-input', updateProdductQuantity);
$(document).on('click', '#shopping-cart-badge-container .shopping-cart-list-item .product-remove-button', removeProductFromShoppingCart);
$(document).on('click', '.product-pagination-list-item', changeProductPage);
$(document).on('click', '.pagination-left', prevProductPage);
$(document).on('click', '.pagination-right', nextProductPage);
var filter_order = [
    {
        name: 'Relevancia',
        value: 'relevance',
        selected: true
    },
    {
        name: 'Menor precio',
        value: 'price_asc',
        selected: false
    },
    {
        name: 'Mayor precio',
        value: 'price_desc',
        selected: false
    },
    {
        name: 'Nombre A-Z',
        value: 'name_asc',
        selected: false
    },
    {
        name: 'Nombre Z-A',
        value: 'name_desc',
        selected: false
    }
];
/*var filter_stock = [
    {
        name: 'Todos',
        value: 'all',
        selected: true
    },
    {
        name: 'Con stock',
        value: 'stock',
        selected: false
    },
    {
        name: 'Sin stock',
        value: 'no_stock',
        selected: false
    }
];*/
export var products_pagination = {
    page: 1,
    size: 9,
    total: 0,
    totalPages:0
};
var products_array = [];
var scroll_to_products = false;
let current_url = window.location.href;
function loadProductComponentfunction(){
    //check if url has search parameter
    if(window.location.search.length > 0){
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has('search')){
            $('#menu-search-input').val(urlParams.get('search').replaceAll('%',' ')).focus();
        }
    }
    //check if element #products-container exists
    if($('#products-container').length > 0){
        initProductsComponent();
    }
}
export { loadProductComponentfunction };
function initProductsComponent(){
    let AppendContent = '';
    AppendContent += '<div id="products-filter-container" class="d-flex justify-content-between">';
        AppendContent += ' <p id="filter-title" class="align-self-center">Filtros</p>';
        if(current_url.indexOf('/Categories') == -1){
            AppendContent += '<select id="product-category-filter" class="align-self-center filter-input form-select form-select-sm" aria-label=".form-select-sm example"></select>';
        }
        AppendContent += '<select id="product-brand-filter" class="align-self-center filter-input form-select form-select-sm" aria-label=".form-select-sm example"></select>';
        AppendContent += '<select id="product-order-filter" class="align-self-center filter-input form-select form-select-sm" aria-label=".form-select-sm example">'
            filter_order.forEach(function(item){
                AppendContent += '<option value="'+item.value+'" '+((item.selected)?'selected':'')+'>'+item.name+'</option>';
            });
        AppendContent += '</select>';
        /*AppendContent += '<select id="product-stock-filter" class="align-self-center filter-input form-select form-select-sm" aria-label=".form-select-sm example">';
            filter_stock.forEach(function(item){
                AppendContent += '<option value="'+item.value+'" '+((item.selected)?'selected':'')+'>'+item.name+'</option>';
            });
        AppendContent += '</select>';*/
    AppendContent += '</div>';
    AppendContent += '<nav class="product-pagination-container d-flex justify-content-center">';
        AppendContent += '<i class="fa-solid fa-chevron-left align-self-center pagination-left pagination-arrow"></i>';
        AppendContent += '<ul class="product-pagination-list align-self-center"></ul>';
        AppendContent += '<i class="fa-solid fa-chevron-right align-self-center pagination-right pagination-arrow"></i>';
    AppendContent += '</nav>';
    AppendContent += '<ul id="products-list" class="general-body-padding d-flex flex-wrap justify-content-around"></ul>';
    AppendContent += '<nav class="product-pagination-container d-flex justify-content-center">';
        AppendContent += '<i class="fa-solid fa-chevron-left align-self-center pagination-left pagination-arrow"></i>';
        AppendContent += '<ul class="product-pagination-list align-self-center"></ul>';
        AppendContent += '<i class="fa-solid fa-chevron-right align-self-center pagination-right pagination-arrow"></i>';
    AppendContent += '</nav>';
    $('#products-container').append(AppendContent);
    const getCategoriesRequest = new Promise((resolve, reject) => {
        GetMethodFunction('/WebPage/Category/GetAll',null, function(response){
            resolve(response);
        }, function(){resolve(null);});
    });
    const getBrandsRequest = new Promise((resolve, reject) => {
        GetMethodFunction('/WebPage/Brand/GetAll',null, function(response){
            resolve(response);
        }, function(){resolve(null);});
    });
    // Esperamos a que las dos peticiones se completen
    Promise.all([getCategoriesRequest, getBrandsRequest])
    .then((results) => {
        // results[0] is the response from getCategoriesRequest
        // results[1] is the response from getBrandsRequest
        if(results[0] != null){
            if(results[0].status == 1){
                //get current url
                
                let AppendContent = '';
                if(current_url.indexOf('/Categories') > -1){
                    results[0].data.forEach(function(item){
                        AppendContent += '<li class="category-list-item">';
                            AppendContent += '<img src="'+item.path+'" alt="category-1" class="category-img">';
                            AppendContent += '<h6 class="category-title">'+item.name+'</h6>';
                            AppendContent += '<i class="category-checkbox fa-regular fa-square'+(category.id==item.id?'-check':'')+'" value="'+item.id+'"></i>';
                        AppendContent += '</li>';
                    });
                    $('#category-list').html(AppendContent);
                }else{
                    AppendContent += '<option value="all">Todas las categorías</option>';
                    results[0].data.forEach(function(item){
                        AppendContent += '<option value="'+item.id+'"'+(typeof category !== 'undefined' && category.id == item.id?' selected':'')+'>'+item.name+'</option>';
                    });
                    $('#product-category-filter').append(AppendContent);
                
                }
            }
        }
        if(results[1] != null){
            if(results[1].status == 1){
                let AppendContent = '';
                AppendContent += '<option value="all" selected>Todas las marcas</option>';
                results[1].brands.forEach(function(item){
                    AppendContent += '<option value="'+item.id+'"'+(typeof brand !== 'undefined' &&  brand.id == item.id?' selected':'')+'>'+item.name+'</option>';
                });
                $('#product-brand-filter').append(AppendContent);
            }
        }
        getProducts();
    })
    .catch(error => console.error(error));
}
function getProducts(){
    let category_ids = [];
    if(current_url.indexOf('/Categories') > -1){
        category_ids = $('#category-list .fa-square-check').map(function(){
            return $(this).attr('value');
        }).get(); 
    }else{
        category_ids = [$('#product-category-filter').val()];
    }
    let data = {
        pagination: products_pagination,
        category_ids: category_ids,
        brand_ids: [$('#product-brand-filter').val()],
        order_id: $('#product-order-filter').val(),
        stock_id: $('#product-stock-filter').val(),
        search: $('#menu-search-input').val(),
        exclude_ids: []
    };
    PostMethodFunction('/WebPage/Catalogue/Product/GetPage',data, null, showProducts, function(){});
}
export { getProducts };
function showProducts(response){
    products_array = response.data;
    products_pagination = response.pagination;
    let appendContent = '';
    let product_image_path = '';
    $.each(products_array, function(index, item){
        appendContent += '<li class="product-list-item d-flex flex-column" product-id="'+item.id+'">';
            if(item.images.length  == 0){
                product_image_path = 'images/product/no-image.png';
            }else{
                //select random image path
                product_image_path = item.images[Math.floor(Math.random() * item.images.length)].path;
            }
            appendContent += '<div class="product-list-item-image" style="background-image: url(\'/'+product_image_path+'\');"></div>';
            appendContent += '<h2 class="product-list-item-title">'+item.name+'</h2>';
            appendContent += '<div class="product-list-item-categories-container mb-0 mt-auto">';
                $.each(item.categories, function(index, category){
                    appendContent += '<p class="product-list-item-category">'+category.name+(index==item.categories.length-1?'.':',')+'</p>';
                });
            appendContent += '</div>';
            appendContent += '<div class="product-list-item-sizes-container my-0">';
                $.each(item.sizes, function(index, size){
                    appendContent += '<span class="product-list-item-size badge '+(index==0?'selected':'disabled')+'" size-id="'+size.id+'">'+size.size+'</span>';
                });
            appendContent += '</div>';
            appendContent += '<div class="product-list-item-price-container d-flex justify-content-start my-0">';
                if(item.has_discount == true) appendContent += '<span class="align-self-center product-list-item-price-discount"></span>';
                appendContent += '<span class="align-self-center product-list-item-price"></span>';
            appendContent += '</div>';
            appendContent += '<div class="product-list-item-buttons-container row d-flex justify-content-between my-0">';
                appendContent += '<a href="/products/'+item.url+'" class="col-5 col-md-3 product-list-item-view d-flex justify-content-center"><i class="fa-solid fa-eye align-self-center"></i></a>';
                appendContent += '<div class="col-12 col-md-5 product-list-item-quantity-container d-flex justify-content-between">';
                    appendContent += '<i class="fa-solid fa-chevron-down align-self-center quantity-down"></i>';
                    appendContent += '<input type="number" class="text-center align-self-center product-list-item-input" value="1" min="1">';
                    appendContent += '<i class="fa-solid fa-chevron-up align-self-center quantity-up"></i>';
                appendContent += '</div>';
                appendContent += '<button class="col-5 col-md-3 product-list-item-shopping-cart d-flex justify-content-center add-to-shopping-cart"><i class="fa-solid fa-cart-shopping align-self-center"></i></button>';
            appendContent += '</div>';
        appendContent += '</li>';
    });
    $('#products-list').html(appendContent);
    $.each($('#products-list .product-list-item'), function(index, item){
        showProductPrice($(item));
    });
    setProductPagination();
    //scroll to product list top
    if(scroll_to_products){
        $('html, body').animate({scrollTop: $('#products-container').offset().top}, 500);
    }else{
        scroll_to_products = true;
    }
}
function downQuantity(){
    let input = $(this).parent().find('.product-list-item-input');
    let value = parseInt(input.val());
    if(value > 1){
        input.val(value-1);
    }
}
function upQuantity(){
    let input = $(this).parent().find('.product-list-item-input');
    let value = parseInt(input.val());
    input.val(value+1);
}
function changeProductSize(){
    let container = $(this).parent().parent();
    $(this).parent().find('.product-list-item-size').removeClass('selected').addClass('disabled');
    $(this).addClass('selected').removeClass('disabled');
    showProductPrice(container);
}
function showProductPrice(container){
    let product_id = container.attr('product-id');
    let selected_size = container.find('.product-list-item-size.selected');
    //get product size info
    let product = products_array.find(x => x.id == product_id);
    if(product == null) return;
    let product_size = product.sizes.find(x => x.id == selected_size.attr('size-id'));
    if(product_size == null) return;
    if(product.has_discount == true){
        if(product_size.original_price==null || product_size.original_price==undefined){
            product_size.original_price = product_size.price;
        }
        product_size.price = product_size.price - (product_size.price * (product.discount.discount/100));
        container.find('.product-list-item-price-discount').html('$'+product_size.original_price.toLocaleString().replace(',', '.')+' COP');
        container.find('.product-list-item-price').html('$'+product_size.price.toLocaleString().replace(',', '.')+' COP');
    }else{
        container.find('.product-list-item-price-discount').html('');
        container.find('.product-list-item-price').html('$'+product_size.price.toLocaleString().replace(',', '.')+' COP');
    }
    
}
var productMaxPagedView = 3;
function setProductPagination(){
    $('.product-pagination-list').html('');
    let appendContent = '';
    products_pagination.page = parseInt(products_pagination.page);
    let start = (products_pagination.page - productMaxPagedView) > 0 ? (products_pagination.page - productMaxPagedView) : 1;
    let end = (products_pagination.page + productMaxPagedView) < products_pagination.totalPages ? (products_pagination.page + productMaxPagedView) : products_pagination.totalPages;
    if(start > 1){
        appendContent += '<li class="product-pagination-list-item" page="1">1</li>';
        if(start > 2){
            appendContent += '<li class="product-pagination-list-item disabled" page="0">...</li>';
        }
    }
    for(let i = start; i <= end; i++){
        appendContent += '<li class="product-pagination-list-item'+(i == products_pagination.page?' active':'')+'" page="'+i+'">'+i+'</li>';
    }
    if(end < products_pagination.totalPages){
        if(end < products_pagination.totalPages - 1){
            appendContent += '<li class="product-pagination-list-item disabled" page="0">...</li>';
        }
        appendContent += '<li class="product-pagination-list-item" page="'+products_pagination.totalPages+'">'+products_pagination.totalPages+'</li>';
    }
    $('.product-pagination-list').append(appendContent);
}
function changeProductPage(){
    let page = $(this).attr('page');
    if(page != products_pagination.page){
        $('.product-pagination-list-item .active').removeClass('active');
        $(this).addClass('active');
        products_pagination.page = page;
        getProducts();
    }
}
function prevProductPage(){
    if(products_pagination.page > 1){
        products_pagination.page--;
        $('.product-pagination-list-item .active').removeClass('active').prev().addClass('active');
        getProducts();
    }
}
function nextProductPage(){
    if(products_pagination.page < products_pagination.totalPages){
        products_pagination.page++;
        $('.product-pagination-list-item .active').removeClass('active').next().addClass('active');
        getProducts();
    }
}
function addProductToShoppingCart(){
    let container = $(this).parent().parent();
    let product_id = container.attr('product-id');
    let quantity = container.find('.product-list-item-input').val();
    let size_id = container.find('.product-list-item-size.selected').attr('size-id');
    SP_addProductToShoppingCart(
        product_id,
        quantity,
        size_id,
        null,
        function(response){
            container.find('.product-list-item-input').val('1');
            SP_getCurrentShoppingCart();
        }
    );
}
function decreaseProductQuantity(e){
    e.preventDefault();
    e.stopPropagation();
    let container = $(this).closest('.shopping-cart-list-item');
    let quantity_container = container.find('.product-quantity-input');
    let quantity = parseInt(quantity_container.val());
    if(quantity > 1){
        quantity--;
        quantity_container.val(quantity);
        SP_updateProductQuantity(container.attr('value'), quantity);
    }
}
function increaseProductQuantity(e){
    e.preventDefault();
    e.stopPropagation();
    let container = $(this).closest('.shopping-cart-list-item');
    let quantity_container = container.find('.product-quantity-input');
    let quantity = parseInt(quantity_container.val());
    quantity++;
    quantity_container.val(quantity);
    SP_updateProductQuantity(container.attr('value'), quantity);
}
function updateProdductQuantity(e){
    e.preventDefault();
    e.stopPropagation();
    let container = $(this).closest('.shopping-cart-list-item');
    let quantity_container = container.find('.product-quantity-input');
    let quantity = parseInt(quantity_container.val());
    if(quantity >= 1){
        SP_updateProductQuantity(container.attr('value'), quantity);
    }
    
}
function removeProductFromShoppingCart(e){
    e.preventDefault();
    e.stopPropagation();
    let shopping_cart_product_id = $(this).attr('value');
    swallMessage(
        '¿Estás seguro de eliminar este producto?'
        , 'Esta acción no se puede deshacer'
        , 'warning'
        , 'Si, eliminar'
        , 'Cancelar'
        , null
        , function(){
            SP_removeProductFromShoppingCart(shopping_cart_product_id);
        }, null);
}
function toggleCategoryCheckbox(){
    if($(this).hasClass('fa-square-check')){
        $(this).removeClass('fa-square-check').addClass('fa-square');
    }else{
        $(this).removeClass('fa-square').addClass('fa-square-check');
    }
    getProducts();
}